import { IonContent, IonFooter, IonPage } from "@ionic/react";
import { useContext, useRef, useState } from "react";
import PDFCollapsiblePanel from "../../components/PDFCollapsiblePanel/PDFCollapsiblePanel";
import PDFCollectionView from "../../components/PDFCollectionView/PDFCollectionView";
import InformationCollectionView from "../../components/PDFCollectionView/InformationCollectionView";
import DesktopBreakpoint from "../../responsive_utilities/desktop_breakpoint";
import PhoneBreakpoint from "../../responsive_utilities/phone_breakpoint";
import TabletBreakpoint from "../../responsive_utilities/tablet_breakpoint";
import { AppContext } from "../../State";
import "../ConformityPage/Conformity.css";
import LoadingOverlay from "react-loading-overlay";

import { Grid, Box } from "@material-ui/core";

const Conformity: React.FC = () => {
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const { state } = useContext(AppContext);

  const [isLoaderActive, setIsLoaderActive] = useState(false);

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  var pdfLocation = `${process.env.PUBLIC_URL}/assets/Conformity/pdf/${
    state.fossPDFDetail.fileName
  }.${state.fossPDFDetail.fileFormat.toLowerCase()}`;

  var pdf_radio = `${process.env.PUBLIC_URL}/assets/Conformity/pdf/${
    state.radioPDFDetail.fileName
  }.${state.radioPDFDetail.fileFormat.toLowerCase()}`;

  var pdf_battery = `${process.env.PUBLIC_URL}/assets/Conformity/pdf/${
    state.batteryPDFDetail.fileName
  }.${state.batteryPDFDetail.fileFormat.toLowerCase()}`;

  var pdf_declaration = `${process.env.PUBLIC_URL}/assets/Conformity/pdf/${
    state.declarationPDFDetail.fileName
  }.${state.declarationPDFDetail.fileFormat.toLowerCase()}`;

  var common_portal = "https://manuals.daimlertruck.com/"
  var owners_homepage = "https://manuals.daimlertruck.com/truckownermanuals/home"

  function handleCallback(isActive: any) {
    setIsLoaderActive(isActive);
  }

  return (
    <IonPage>
      <IonContent fullscreen ref={contentRef} scrollEvents={true}>
        <LoadingOverlay active={isLoaderActive} spinner>
          <DesktopBreakpoint>
          <div className="conformity-header-container">
            <img
              className="conformity-benz-logo-image"
              // src="assets/images/Wortmarke_Stern-3.svg"
              src={
                process.env.PUBLIC_URL + "/assets/images/Wortmarke_Stern-3.svg"
              }
              alt="Benz Logo"
            ></img>
            {/* <div className="right-container"> */}
            <div className="conformity-right-container">
            <a href={common_portal} target="_blank"
                      rel="noopener">
                    <span className="conformity-link-name">
                      Common Portal
                    </span>
                </a>
                <span className="conformity-link-separator"></span>
                <a href={owners_homepage}>
                    <span className="conformity-link-name">
                      Truck Guides
                    </span>
                </a>
                </div>
          </div>
          </DesktopBreakpoint>
          <TabletBreakpoint>
          <div className="conformity-header-container">
            <img
              className="conformity-benz-logo-image"
              // src="assets/images/Wortmarke_Stern-3.svg"
              src={
                process.env.PUBLIC_URL + "/assets/images/Wortmarke_Stern-3.svg"
              }
              alt="Benz Logo"
            ></img>
            {/* <div className="right-container"> */}
            <div className="conformity-right-container">
            <a href={common_portal} target="_blank"
                      rel="noopener">
                    <span className="conformity-link-name">
                      Common Portal
                    </span>
                </a>
                <span className="conformity-link-separator"></span>
                <a href={owners_homepage}>
                    <span className="conformity-link-name">
                      Truck Guides
                    </span>
                </a>
                </div>
          </div>
          </TabletBreakpoint>
          <PhoneBreakpoint>
          <div className="conformity-header-container">
            <img
              className="conformity-benz-logo-image"
              // src="assets/images/Wortmarke_Stern-3.svg"
              src={
                process.env.PUBLIC_URL + "/assets/images/Wortmarke_Stern-3.svg"
              }
              alt="Benz Logo"
            ></img>
            {/* <div className="right-container"> */}
            <div className="conformity-right-container">
            <a href={common_portal} target="_blank"
                      rel="noopener"
                >
                  <img
                    // src="assets/images/to_home.png"
                    src={process.env.PUBLIC_URL + "/assets/images/to_home.png"}
                    className="link-image-in-manual link-image-phone"
                    alt="home-icon"
                    
                  ></img>
                </a>
                <a href={owners_homepage}
                    >
                      <img
                        // src="assets/images/icon-back.png"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icon-back.png"
                        }
                        className="link-image-in-manual link-image-phone"
                        alt="home-icon"
                      ></img>
                    </a>
                </div>
          </div>
          </PhoneBreakpoint>
          <div className="conformity-content-one">
            <span className="conformity-content-separator"></span>
            <div className="conformity-h2">
              MERCEDES-BENZ TRUCKS: DECLARATION OF CONFORMITY.
            </div>
            <div className="conformity-t1">
              according EU directive 2014/53/EU and UK CA.
            </div>
            <div className="conformity-t2">
              The following declarations are for all standard and optional
              components in all Mercedes-Benz Trucks, which have to fulfill EU
              directive 2014/53/EU and UK CA.
            </div>
            <div className="conformity-t2">
              Please note that your vehicle may not be equipped with all of the
              listed components.
            </div>
            <DesktopBreakpoint>
              <PDFCollectionView
                numberOfRows={3}
                parentCallback={handleCallback}
              />
            </DesktopBreakpoint>
            <TabletBreakpoint>
              <PDFCollectionView
                numberOfRows={3}
                parentCallback={handleCallback}
              />
            </TabletBreakpoint>
            <PhoneBreakpoint>
              <PDFCollectionView
                numberOfRows={1}
                parentCallback={handleCallback}
              />
            </PhoneBreakpoint>
            <div className="confotmity-content-two">
              <span className="conformity-content-separator"></span>
              <div className="conformity-t3">
                FREE & OPEN SOURCE SOFTWARE (FOSS).
              </div>
              <a
                href={pdfLocation}
                target="_blank"
                rel="noopener noreferrer"
                className="pdf-link-con"
              >
                <div className="download-img-con"></div>
                <span className="pdf-detail-con">
                  <strong className="pdf-file-name-con">
                    {state.fossPDFDetail.fileName}
                  </strong>
                  <span className="pdf-file-size-con">
                    {state.fossPDFDetail.fileSize},{" "}
                    {state.fossPDFDetail.fileFormat}
                  </span>
                  {/* <span className="pdf-separator-line"></span> */}
                </span>
              </a>
              <span className="conformity-content-separator"></span>
              <div className="conformity-t3">Radio regulatory information.</div>
              <a
                href={pdf_radio}
                target="_blank"
                rel="noopener noreferrer"
                className="pdf-link-con"
              >
                <div className="download-img-con"></div>
                <span className="pdf-detail-con">
                  <strong className="pdf-file-name-con">
                    {state.radioPDFDetail.fileName}
                  </strong>
                  <span className="pdf-file-size-con">
                    {state.radioPDFDetail.fileSize},{" "}
                    {state.radioPDFDetail.fileFormat}
                  </span>
                  {/* <span className="pdf-separator-line"></span> */}
                </span>
              </a>
              <span className="conformity-content-separator"></span>
              <div className="conformity-t3">Notes on Batteries.</div>
              <div className="conformity-t1">
              According to Art. 10 para. 1 s. 1, Part A of Annex IV Reg. (EU) 2023/1542
              </div>
              <a
                href={pdf_battery}
                target="_blank"
                rel="noopener noreferrer"
                className="pdf-link-con"
              >
                <div className="download-img-con"></div>
                <span className="pdf-detail-con">
                  <strong className="pdf-file-name-con">
                    {state.batteryPDFDetail.fileName}
                  </strong>
                  <span className="pdf-file-size-con">
                    {state.batteryPDFDetail.fileSize},{" "}
                    {state.batteryPDFDetail.fileFormat}
                  </span>
                  {/* <span className="pdf-separator-line"></span> */}
                </span>
              </a>
              <div className="conformity-t1">
              EU Declaration of Conformity
              </div>
              <a
                href={pdf_declaration}
                target="_blank"
                rel="noopener noreferrer"
                className="pdf-link-con"
              >
                <div className="download-img-con"></div>
                <span className="pdf-detail-con">
                  <strong className="pdf-file-name-con">
                    {state.declarationPDFDetail.fileName}
                  </strong>
                  <span className="pdf-file-size-con">
                    {state.declarationPDFDetail.fileSize},{" "}
                    {state.declarationPDFDetail.fileFormat}
                  </span>
                  {/* <span className="pdf-separator-line"></span> */}
                </span>
              </a>
              <div className="conformity-t1">
              Information (including safety information) on portable batteries
              </div>
              <DesktopBreakpoint>
              <InformationCollectionView
                numberOfRows={3}
                parentCallback={handleCallback}
              />
            </DesktopBreakpoint>
            <TabletBreakpoint>
              <InformationCollectionView
                numberOfRows={3}
                parentCallback={handleCallback}
              />
            </TabletBreakpoint>
            <PhoneBreakpoint>
              <InformationCollectionView
                numberOfRows={1}
                parentCallback={handleCallback}
              />
            </PhoneBreakpoint>
            </div>
            <span className="conformity-content-separator"></span>
            <span className="conformity-footer-separator separator-two"></span>
            <PDFCollapsiblePanel />
          </div>
          <IonFooter class="ionic-footer-in-conformity-page">
            <div className="conformity-footer-container">
              <div className="footer-button-container">
                <span className="conformity-footer-text">
                  Declaration of Conformity
                </span>
                <a
                  className="back-to-top-conformity"
                  onClick={() => {
                    scrollToTop();
                  }}
                >
                  To the top
                </a>
              </div>
              <span className="conformity-footer-separator"></span>
              <span className="conformity-footer-separator"></span>
            </div>
          </IonFooter>
        </LoadingOverlay>
      </IonContent>
    </IonPage>
  );
};

export default Conformity;
