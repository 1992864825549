import "./PDFCollectionView.css";
import { Card, Container, Row, Col } from "reactstrap";
import apiClient from "../../services/http-common";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../State";
import { useHistory } from "react-router-dom";
import logger from "../../Utils/logger"
interface ContainerProps {
  numberOfRows: number;
  parentCallback: any;
}

const PDFCollectionView: React.FC<ContainerProps> = (props) => {
  const { state, dispatch } = useContext(AppContext);

  const history = useHistory();

  const [pdfInfo, setPDFInfo] = useState([
    {
      id: 0,
      fileName: "",
      fileFormat: "",
      fileSize: "",
      filePath: "",
      createdAt: "",
      updatedAt: "",
    },
  ]);

  useEffect(() => {
    getPDFContents();
  }, []);

  const getPDFContents = async () => {
    props.parentCallback(true);
    try {
      var result: any;
      var pdfData: any;

      result = await apiClient.get(`/retrivergetConformityDetails`);
      pdfData = result.data?.conformityPdfDetails;

      if (pdfData != null) {
        var pdfDataSet = result.data?.conformityPdfDetails.filter(
          (item) => !item.fileName.includes("FOSS License") && !item.fileName.includes("Radio") && !item.fileName.includes("Batteries") && !item.fileName.includes("nformation") && !item.fileName.includes("Declaration")
        );
        var fossData = result.data?.conformityPdfDetails.filter((item) =>
          item.fileName.includes("FOSS License")
        );

        var radioData = result.data?.conformityPdfDetails.filter((item) =>
          item.fileName.includes("Radio")
        );

        var batteryData = result.data?.conformityPdfDetails.filter((item) =>
          item.fileName.includes("Batteries")
        );

        var declarationData = result.data?.conformityPdfDetails.filter((item) =>
          item.fileName.includes("Declaration")
        );

        dispatch({
          type: "setFossPDFDetail",
          payload: {
            fossPDFDetail: fossData[0],
          },
        });

        dispatch({
          type: "setRadioPDFDetail",
          payload: {
            radioPDFDetail: radioData[0],
          },
        });

        dispatch({
          type: "setBatteryPDFDetail",
          payload: {
            batteryPDFDetail: batteryData[0],
          },
        });

        dispatch({
          type: "setDeclarationPDFDetail",
          payload: {
            declarationPDFDetail: declarationData[0],
          },
        });

        setPDFInfo(pdfDataSet);
      }
      props.parentCallback(false);
      logger.info(`Get PDF Contents API result`, pdfData);
    } catch (err: any) {
      logger.info(`Get PDF Contents API ERROR ${err}`);
      dispatch({
        type: "setApiErrorCode",
        payload: {
          apiErrorCode: err?.response?.status,
        },
      });
      history.push("/truckownermanuals/error");
      props.parentCallback(false);
    }
  };

  return (
    <div className="pdf-container-d1">
      <Container className="pdf-container-c1">
        <Row xs={props.numberOfRows}>
          {[...Array(pdfInfo.length)].map((e, i) => {
            var pdfLocation = `${
              process.env.PUBLIC_URL
            }/assets/Conformity/pdf/${pdfInfo[i].fileName}.${pdfInfo[
              i
            ].fileFormat.toLowerCase()}`;
            return (
              <Col key={i} className="pdf-col">
                <Card className="card border-0">
                  <div
                    className="pdf-layout__item"
                    onClick={() => {
                      logger.info(`item id ${pdfInfo[i].id}`);
                    }}
                  >
                    <a
                      href={pdfLocation}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="pdf-link-con"
                    >
                      <div
                        // src="assets/images/play.svg"
                        className="download-img-con"
                      ></div>
                      <span className="pdf-detail-con">
                        <strong className="pdf-file-name-con">
                          {pdfInfo[i].fileName}
                        </strong>
                        <span className="pdf-file-size-con">
                          {pdfInfo[i].fileSize}, {pdfInfo[i].fileFormat}
                        </span>
                      </span>
                    </a>
                    {/* <span className="pdf-separator-line"></span> */}
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default PDFCollectionView;
