import { IonContent, IonPage, IonFooter } from "@ionic/react";
import FooterContainer from "../../components/FooterContainer/FooterContainer";
import TruckModelsParentContainer from "../../components/TruckModelsParentContainer/TruckModelsParentContainer";
import "./Home.css";
import { useContext, useEffect, useRef, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { AppContext } from "../../State";
import { useHistory } from "react-router-dom";
import { vehicleVINDetails } from "../../Utils/VehicleVINDetails";
import logger from "../../Utils/logger"
import CommonHeaderComponent from "../../components/CommonHeaderComponent/CommonHeaderComponent";
const Home: React.FC = () => {
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const history = useHistory();
  const { state, dispatch } = useContext(AppContext);

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };
  let VINNumberFromVehicle = "W1T98300310628787";

  // to get vehicle Information from provided VIN number

  let vehicleDetails;

  const getVehicleInformation = () => {
    Object.keys(vehicleVINDetails).map((vehicle) => {
      if (vehicle == VINNumberFromVehicle) {
        vehicleDetails = vehicleVINDetails[vehicle];
      }
    });
    // logger.info("vechileDetails", vehicleDetails);

    //update VIN boolean or flag
    dispatch({
      type: "setisVINAvailable",
      payload: {
        isVINAvailable: vehicleDetails?.vehicleVIN ? true : false,
      },
    });
    // storing of only VIN number
    dispatch({
      type: "setVehicleVIN",
      payload: {
        vehicleVIN: vehicleDetails?.vehicleVIN
          ? vehicleDetails?.vehicleVIN
          : "",
      },
    });

    // store complete vechileInformation based on VIN number provided
    dispatch({
      type: "setVehicleInfoBasedOnVIN",
      payload: {
        vehicleInfoBasedOnVIN: vehicleDetails ? vehicleDetails : {},
      },
    });
  };

  useEffect(() => {
    // to get vehicle info initially
    // getVehicleInformation();
    // set Family and Model
    // dispatch({
    //   type: "setSelectedFamilyModel",
    //   payload: {
    //     selectedFamilyModel: "Actros",
    //   },
    // });
    // dispatch({
    //   type: "setSelectedVehicleTypeModel",
    //   payload: {
    //     selectedVehicleTypeModel: {},
    //   },
    // });
  }, []);
  // useEffect(() => {
  //   if (state.isVINAvailable || state.vehicleVIN) {
  //     history.push("/truckownermanuals/tiled-items");
  //   }
  // }, [state.isVINAvailable || state.vehicleVIN]);

  return (
    <IonPage>
      <IonContent
        className="home"
        fullscreen
        ref={contentRef}
        scrollEvents={true}
      >
        <LoadingOverlay active={state.isHomeLoaderActive} spinner>
          <div className="home-header">
            <CommonHeaderComponent />
          </div>
          <TruckModelsParentContainer />

          <IonFooter>
            <div className="home-footer-container">
              <FooterContainer onClickScrollToTop={scrollToTop} />
            </div>
          </IonFooter>
        </LoadingOverlay>
      </IonContent>
    </IonPage>
  );
};

export default Home;
